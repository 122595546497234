(function($, undefined) {

    var $body = $('body');


    function init() {
        // initialize commons components
        cookiesComponent();
        //menuComponent();
        dropsComponent();
        
        // initialize pages
        if ($('.body-home').length > 0) {
            pageHome();
        }

        $body.on('click', '.js-ulmenu a', function(e) {
            var $this = $(this);

            scrollToElement($($this.attr('href')));
        })

        $body.on('click', '.js-goto', function (e) {
            e.preventDefault();
            var $this = $(this);

            scrollToElement($($this.attr('href')));
        });

        $body.on('click', '.js-checkbox-privacity', function (e) {
			$(this).is(":checked") ? $(this).val(true) : $(this).val(false);
        });
        
        $body.on('click', '.js-show-cookies', function (e) {
            $(".js-show-cookies").hide();
            $(".js-cookies").show();
        });
    }

    init();
    
})(jQuery);


