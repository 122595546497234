(function() {
    'use strict';

    function isMobile(bodyWrapper) {
        if (navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i)) {
            bodyWrapper.classList.add('is-mobile');
        }
        if (navigator.userAgent.match(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i)) {
            bodyWrapper.classList.remove('is-mobile');
            bodyWrapper.classList.add('is-tablet');
        }
        if (navigator.userAgent.match(/(Tablet)/i)) {
            bodyWrapper.classList.remove('is-mobile');
            bodyWrapper.classList.add('is-tablet');
        }
    }

    function isiOS(bodyWrapper) {
        if (navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i)) {
            bodyWrapper.classList.add('is-ios');
        }
    }

    function isIE(bodyWrapper) {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            bodyWrapper.classList.add('is-ie');
        }
    }

    function init() {
        var bodyWrapper = document.querySelector('body');

        isMobile(bodyWrapper);
        isiOS(bodyWrapper);
        isIE(bodyWrapper);
    }

    init();
})();
