var pageRegister = function () {

    var $body = $('body');
    var companions = 0;

    function checkCompanions() {
        if ((companions)<3) {
            return true;
        }
        
        return false;
    }

    function incrementButton($button) {
        var oldValue = $button.parent().find("input").val();

        $button.blur();
        if ($button.hasClass("inc")) {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            // Don't allow decrementing below zero
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }

        $button.parent().find("input").val(newVal);
    }
    
    function init() {
        realTimeValidate($('form.js-register'));
        
        $body.on('submit', 'form.js-register', function (e) {
            var $this = $(this);
            var $error = $this.find('.js-alert').first().find('.alert').first();
            
            $error.removeClass('form__message--error').hide();
            $error.find('span').html('');

            if (validate($this, $error)) {
                scrollToElement($('.form__error:first'));
                return false;
            } else {
                return true;
            }
        });

        $body.on('click', '.clone', function () {
            if (checkCompanions()) {
                companions++;
                $('.js-clone-adult .box_1').clone().appendTo('.js-adults');

                var $last = $('.js-adults .box_1:last');
                var total = $('.js-adults .box_1').length;
                $last.find('.js-number').html(total);

                //var $check = $last.find('.js-check');
                $last.find('.js-check').each(function () {
                    var $check = $(this);
                    $check.find('input').attr('id', $check.data('activity') + '_' + total);
                    $check.find('label').attr('for', $check.data('activity') + '_' + total);

                });

                $last.html($last.html().replaceAll('adult[]', 'adult[' + (total - 1) +']'));
    
                incrementButton($(this));
                realTimeValidate($('form.js-register'));
            }
        });

        $body.on('click', '.remove', function () {
            companions--;
            $('.js-adults .box_1:last').remove();

            incrementButton($(this));
        });

        $body.on('click', '.remove2', function () {
            companions--;
            $('.js-kids .box_2:last').remove();

            incrementButton($(this));
        });

        $body.on('click', '.clone2', function () {
            if (checkCompanions()) {
                companions++;
                $('.js-clone-kid .box_2').clone().appendTo('.js-kids');

                $('.js-kids .box_2:last .js-number').html($('.js-kids .box_2').length);

                var $last = $('.js-kids .box_2:last');
                var total = $('.js-kids .box_2').length;
                $last.html($last.html().replaceAll('kid[]', 'kid[' + (total - 1) +']'));
    
                incrementButton($(this));
                realTimeValidate($('form.js-register'));
            }
        });
    }

    $( document ).ready(function() {
        init();
    });
};