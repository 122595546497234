function Serializer() {}

Serializer.input = function(key, value) {
    return key+'='+encodeURIComponent(value);
};

Serializer.array = function(key, values) {
    var serializedArray = '';

    for (i=0; i<values.length; i++) {
        serializedArray += key+'[]='+encodeURIComponent(values[i])+'&';
    }

    return serializedArray;
};

Serializer.serialize = function(o) {
    var i,
        serializedData = '',
        keys = Object.keys(o);

    for (i=0; i<keys.length; i++) {
        serializedData += (Array.isArray(o[keys[i]])) ? Serializer.array(keys[i], o[keys[i]]) : Serializer.input(keys[i], o[keys[i]])+'&';
    }

    return serializedData;
};
