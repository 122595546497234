function clone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

function isJSON(str) {
    var result;

    try {
        result = JSON.parse(str);
    } catch (e) {
        return false;
    }
    return result;
}

function fixFP(number, maxError) {
    return parseFloat(number.toFixed(maxError));
}

function formatMoney(n, c, d, t, format, currency) {
    c = isNaN(c = Math.abs(c)) ? 2 : c;
    d = d == undefined ? "." : d;
    t = t == undefined ? "," : t;
    s = n < 0 ? "-" : "";
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
    var j = i.length;
    j = j > 3 ? j % 3 : 0;

    var number = s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");

    return format.replace(':num', number).replace(':currency', currency);
}

function scrollToElement(element) {
    
    var position = element.offset().top - 180;
    $("html, body").animate({
        scrollTop: position
    }, 500);
}

function fixWidthHelper(e, ui) {
    ui.children().each(function() {
        var $this = $(this);

        $this.width($this.width());
    });

    return ui;
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

function getQueryString(param) {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    
    if (urlParams.has(param)) {
        return urlParams.get(param);
    }

    return '';
}

function serializeArrObj(arr) {
    var str = [];

    $.each(arr, function (index, item) {
        str.push(encodeURIComponent(item.name) + "=" + encodeURIComponent(item.value));
    });
    
    return str.join("&");
}