var pageHome = function () {

    var $body = $('body');
      
    function init() {
        realTimeValidate($('form.js-lead'));
        
        $body.on('submit', 'form.js-lead', function (e) {
            var $this = $(this);
            var $error = $this.find('.alert').first();
            
            $error.removeClass('form__message--error').hide();
            $error.find('span').html('');

            if (validate($this, $error)) {
                scrollToElement($('.form__error:first'));
                return false;
            } else {
                return true;
            }
        });
    }

    $( document ).ready(function() {
        init();
    });
};