
// ACCORDION 

var accordion = (function(){
  
    var $accordion = $('.js-accordion');
    var $accordion_header = $accordion.find('.js-accordion-header');
    var $accordion_item = $('.js-accordion-item');
   
    var settings = {
      speed: 400,
      
      oneOpen: false
    };
      
    return {
      init: function($settings) {
        $accordion_header.on('click', function() {
          accordion.toggle($(this));
          
          setTimeout(() => {
            $('body, html').animate({
              scrollTop: $(this).offset().top - 200
             }, 500)
          }, 400)
        });
        
        $.extend(settings, $settings); 
        
        if(settings.oneOpen && $('.js-accordion-item.active').length > 1) {
          $('.js-accordion-item.active:not(:first)').removeClass('active');
        }
        
        $('.js-accordion-item.active').find('> .js-accordion-body').show();
      },
      toggle: function($this) {
              
        if(settings.oneOpen && $this[0] != $this.closest('.js-accordion').find('> .js-accordion-item.active > .js-accordion-header')[0]) {
          $this.closest('.js-accordion')
                 .find('> .js-accordion-item') 
                 .removeClass('active')
                 .find('.js-accordion-body')
                 .slideUp()
        }
        
        $this.closest('.js-accordion-item').toggleClass('active');
        $this.next().stop().slideToggle(settings.speed);
      }
    }
  })();
  
  $(document).ready(function(){

    $('.slick-carousel').slick({
      infinite: true,
        slidesToShow: 1, 
        slidesToScroll: 1, 
        arrows: true, 
        dots: false
    });

    
    $(function() {
        var header = $(".header__content-head-cols");
    
        $(window).scroll(function() {    
            var scroll = $(window).scrollTop();
            if (scroll >= 50) {
                header.addClass("fixed");
            } else {
                header.removeClass("fixed");
            }
        });
    
    });

    $( ".js-open-menu" ).click(function() {
        $('.header__mobile-menu').addClass('open');
    });

    $( ".js-close-menu, .header__mobile-menu-item" ).click(function() {
        $('.header__mobile-menu').removeClass('open');
    });


    accordion.init({ speed: 300, oneOpen: true });
  });


  // MODAL

  var modalBtns = [...document.querySelectorAll(".modal-toggle")];
modalBtns.forEach(function(btn){
  btn.onclick = function() {
    var modal = btn.getAttribute('data-modal');
    document.getElementById(modal).style.display = "block";
  }
});

var closeBtns = [...document.querySelectorAll(".modal-close")];
closeBtns.forEach(function(btn){
  btn.onclick = function() {
    var modal = btn.closest('.modal');
    modal.style.display = "none";
  }
});

window.onclick = function(event) {
  if (event.target.className === "modal") {
    event.target.style.display = "none";
  }
}