var cookiesComponent = function () {
    var $body = $('body');

    function initCookies() {
        $body.on('click', '#show-hidden-menu', function() {
            $(".js-cookies").show();
        });
    
        $body.on('click', '.js-button-cookies', function() {

			var exdate = new Date();
            exdate.setDate(exdate.getDate() + 3650);
            var c_value = escape(1) + ((3650 === null) ? "" : "; expires=" + exdate.toUTCString());
            
            if(!$(this).data('accept')) {
                $(".js-cookies").hide();
				c_value = escape(0) + ((3650 === null) ? "" : "; expires=" + exdate.toUTCString());
				document.cookie = 'cookies_status' + "=" + c_value;
                $(".js-show-cookies").show();
                return;
            }
			document.cookie = 'cookies_status' + "=" + c_value;

            initializeGoogleAnalytics();

            $(".js-cookies").hide();
        });
        if (getCookie('cookies_status') == "") {
            $(".js-cookies").show();
        } else {
            $(".js-cookies").hide();
        }
    }

    function init() {
        if ($('.js-cookies').length > 0) {
            initCookies();
        }

        if (getCookie('cookies_status') == "" || getCookie('cookies_status') == "1") {
            $(".js-show-cookies").hide();
        } else {
            $(".js-show-cookies").show();
        }
    }

    $( document ).ready(function() {
        init();
    });
};