function realTimeValidate($form) {
    $form.find('[type=email].required, [type=number].required, [type=text].required, [type=password].required, textarea.required').blur(function (){
        var $this = $(this);
        $this.closest('.js-input').removeClass('form__error');
        $this.closest('.js-input').find('.js-input-error').html('');

        if ($this.val().length == 0) {
            $this.closest('.js-input').addClass('form__error');
            $this.closest('.js-input').find('.js-input-error').html($this.closest('form').data('singularrequired'));
        }
    });

    $form.on('change', 'select.required', function (e){
        var $this = $(this);
        $this.closest('.js-input').removeClass('form__error');
        $this.closest('.js-input').find('.js-input-error').html('');

        if ($this.val().length == 0 || $this.val()=="") {
            $this.closest('.js-input').addClass('form__error');
            $this.closest('.js-input').find('.js-input-error').html($this.closest('form').data('singularrequired'));
        }
    });

    $form.on('change', '[type=checkbox].required', function (e){
        var $this = $(this);
        $this.closest('.js-input').removeClass('form__error');
        $this.closest('.js-input').find('.js-input-error').html('');

        if ($this.prop("checked") == false) {
            $this.closest('.js-input').addClass('form__error');
            $this.closest('.js-input').find('.js-input-error').html($this.closest('form').data('singularrequired'));
        }
    });

    $form.on('change','.required-same', function (e){
        var $this = $(this);
        $this.closest('.js-input').removeClass('form__error');
        var $same = $this.closest('form').find('input[name=' + $this.data('same') + ']')

        if ($this.val().length == 0 || $this.val()!=$same.val()) {
            $this.closest('.js-input').addClass('form__error');
            $same.addClass('error-input');
        }
    });

    $form.on('change','.required-min', function (e){
        var $this = $(this);
        $this.closest('.js-input').removeClass('form__error');
        var min = $this.data('min');

        if ($this.val().length < min) {
            $this.closest('.js-input').addClass('form__error');
            $this.addClass('error-input');
        }
    });

    $form.find('[type=text].validate-email').blur(function (){
        var $this = $(this);
        if ($this.val().length > 0) {
            $this.closest('.js-input').removeClass('form__error');
            $this.closest('.js-input').find('.js-input-error').html('');
            
            if (!isEmail($this.val())) {
                $this.closest('.js-input').addClass('form__error');
                $this.closest('.js-input').find('.js-input-error').html($this.closest('form').data('email'));
            }
        }
    });
    grecaptcha.ready(function() {
		grecaptcha.execute('6LdgXDEpAAAAAAlpII2du6NuzLdv2_I6ZpnGXQNm', {
			action: 'submit'
		})
		.then(function(token) {
			$('#recaptcha_response').val(token);
		});
	});
}



function checkRequired($form, $alert) {
    var retorno = false;
    $form.find('[type=email].required, [type=number].required, [type=text].required, [type=password].required, textarea.required').each(function (index, item){
        var $this = $(item);

        $this.closest('.js-input').removeClass('form__error');
        if ($this.val().length == 0) {
            retorno = true;
            $this.closest('.js-input').addClass('form__error');
            $this.closest('.js-input').find('.js-input-error').html($this.closest('form').data('singularrequired'));
        }
    });

    $form.find('select.required').each(function (index, item){
        var $this = $(item);

        $this.closest('.js-input').removeClass('form__error');
        if ($this.val().length == 0 || $this.val()=="") {
            retorno = true;
            $this.closest('.js-input').addClass('form__error');
            $this.closest('.js-input').find('.js-input-error').html($this.closest('form').data('singularrequired'));
        }
    });

    $form.find('[type=checkbox].required').each(function (index, item){
        var $this = $(item);

        $this.closest('.js-input').removeClass('form__error');
        if ($this.prop("checked") == false) {
            retorno = true;
            $this.closest('.js-input').addClass('form__error');
            $this.closest('.js-input').find('.js-input-error').html($this.closest('form').data('singularrequired'));
        }
    });

    if (retorno) {
        $alert.addClass('form__message--error').find('span').append($form.data('singularrequired') + '<br />');
        $alert.show();
    }

    return retorno;
}

function checkSames($form, $alert) {
    var retorno = false;
    
    $form.find('.required-same').each(function (index, item){
        var $this = $(item);
        var $same = $this.closest('form').find('input[name=' + $this.data('same') + ']');

        if ($this.val().length == 0 || $this.val() != $same.val()) {
            retorno = true;
            $this.closest('.js-input').addClass('form__error');
            $same.addClass('error-input');
        }
    });

    if (retorno) {
        $alert.addClass('form__message--error').find('span').append($form.data('same') + '<br />');
        $alert.show();
    }

    return retorno;
}

function checkMin($form, $alert) {
    var retorno = false;
    
    $form.find('.required-min').each(function (index, item){
        var $this = $(item);
        var min = $this.data('min');

        if ($this.val().length < min) {
            retorno = true;
            $this.closest('.js-input').addClass('form__error');
            $this.addClass('error-input');

            $alert.addClass('form__message--error').find('span').append($this.data('errormin') + '<br />');
            $alert.show();
        }
    });

    if (retorno) {
    }

    return retorno;
}

function isEmail(email) {
    return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
}

function checkEmails($form, $alert) {
    var retorno = false;

    $form.find('.validate-email').each(function (index, item){
        var $this = $(item);

        if ($this.val().length == 0 || !isEmail($this.val())) {
            retorno = true;
            $this.closest('.js-input').addClass('form__error');
        }
    });

    if (retorno) {
        $alert.addClass('form__message--error').find('span').append($form.data('email') + '<br />');
        $alert.show();
    }

    return retorno;
}

function checkNumbers($form, $alert) {
    // TODO
    return false;
}


function validate($form, $alert) {
    return checkRequired($form, $alert) || checkSames($form, $alert) || checkMin($form, $alert)
    || checkEmails($form, $alert) || checkNumbers($form, $alert);
}