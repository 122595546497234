// TODO: @marcos revisar
(function() {
    'use strict';

    ///
    /// Settings
    ///
    var animationDuration = 400;

    function initToTop() {
        var toTopTrigger = document.querySelector('.js-to-top');
        if (!toTopTrigger) {
            return;
        }

        toTopTrigger.addEventListener('click', function(ev) {
            ev.preventDefault();

            scrollTo(document.documentElement, 0, animationDuration);
        });
    }

    function init() {
        initToTop();
    }

    init();
})();
