function CookieManager() {}

CookieManager.testCookie = function() {
    var userName = CookieManager.getCookie('username');

    if (userName) {
        alert('Welcome again ' + userName);
    } else {
        userName = prompt('Please enter your name:', '');
        if (userName) {
            CookieManager.setCookie('username', userName, 365);
        }
    }
};

CookieManager.getCookie = function(name) {
    var i,
        key,
        value,
        cookieList = document.cookie.split(';');

    for (i=0; i<cookieList.length; i++) {
        key = cookieList[i].substr(0, cookieList[i].indexOf('='));
        value = cookieList[i].substr(cookieList[i].indexOf('=')+1);
        key = key.replace(/^\s+|\s+$/g, '');

        if (key === name) {
            return unescape(value);
        }
    }

    return null;
};

CookieManager.setCookie = function(name, value, expirationDays, path) {
    var cookieValue,
        expirationDate = new Date();

    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    cookieValue = escape(value) + ((expirationDays==null) ? '' : '; expires=' + expirationDate.toUTCString()) + ((path) ? ';path=' + path : '');

    document.cookie = name + "=" + cookieValue;
};

CookieManager.isCookiesAccepted = function() {
    return (document.cookie && document.cookie.indexOf("cookie_advice_showed") !== -1);
};
