var dropsComponent = function () {
    var $body = $('body');

    function init() {
        jQuery(document).ready(function (e) {
            function t(t) {
                e(t).bind("click", function (t) {
                    t.preventDefault();
                    e(this).parent().fadeOut()
                })
            }
            e(".dropdown-toggle").click(function () {
                var t = e(this).parents(".button-dropdown").children(".dropdown-menu").is(":hidden");
                e(".button-dropdown .dropdown-menu").hide();
                e(".button-dropdown .dropdown-toggle").removeClass("active");
                if (t) {
                    e(this).parents(".button-dropdown").children(".dropdown-menu").toggle().parents(".button-dropdown").children(".dropdown-toggle").addClass("active")
                }
            });
            e(document).bind("click", function (t) {
                var n = e(t.target);
                if (!n.parents().hasClass("button-dropdown")) e(".button-dropdown .dropdown-menu").hide();
            });
            e(document).bind("click", function (t) {
                var n = e(t.target);
                if (!n.parents().hasClass("button-dropdown")) e(".button-dropdown .dropdown-toggle").removeClass("active");
            })
        });

        var doc = "";
        var dds = "";
        // detect touch
        if ("ontouchstart" in document.documentElement) {
            document.querySelector("html").classList.add("touch");

            doc = document.querySelector("html.touch");
            dds = document.querySelectorAll(".touch .dd");

            doc.addEventListener(
            "click",
            function (event) {
                dds.forEach(function (dd) {
                dd.classList.remove("active");
                });
            },
            false
            );

            dds.forEach(function (dd) {
            dd.addEventListener(
                "click",
                function (event) {
                dd.classList.toggle("active");
                event.stopPropagation();
                },
                false
            );
            });
        }
    }

    $( document ).ready(function() {
        init();
    });
};
